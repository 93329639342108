var utils = require('./utils.js');

var main,
	menuItems,
	closeMenu;

var onMobileClick = function(e){
	main.classList.add('mod-menu-open');
	e.stopPropagation();
};

var onClose = function(e){
	main.classList.remove('mod-menu-open');
	e.stopPropagation();
};

var init = function(){

	if( utils.isMobile ){

		menuItems = document.querySelectorAll('.menu-item');
		closeMenu = document.querySelector('.mod-close-menu');
		main = document.querySelector('main');

		closeMenu.addEventListener('click', onClose);

		for( var m = 0; m < menuItems.length; m++ )
			menuItems[m].addEventListener('touchstart', onMobileClick);

	}

};

module.exports = init();