require('browsernizr/test/touchevents.js');
var Modernizr = require('browsernizr');

/* From Modernizr */
var transitionEvent = function(){
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
      'transition':'transitionend',
      'OTransition':'oTransitionEnd',
      'MozTransition':'transitionend',
      'WebkitTransition':'webkitTransitionEnd'
    }

    for(t in transitions){
        if( el.style[t] !== undefined ){
            return transitions[t];
        }
    }
};

var animationEvent = function(){
    var t;
    var el = document.createElement('fakeelement');
    var animations = {
      'transition':'animationend',
      'OTransition':'oanimationend',
      'MSTransition':'MSAnimationEnd',
      'WebkitTransition':'webkitAnimationEnd'
    }

    for(t in animations){
        if( el.style[t] !== undefined ){
            return animations[t];
        }
    }
};

var findAncestor = function(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
};

var isMobile = function(){
  return Modernizr.touchevents && window.innerWidth < 769;
};

module.exports.transitionEvent = transitionEvent();
module.exports.animationEvent = animationEvent();
module.exports.isMobile = isMobile();
module.exports.findAncestor = findAncestor;

