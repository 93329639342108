var svg4everybody = require('svg4everybody');

var utils = require('./../modules/utils.js');

var Semester = function Semester(element){

	this.element = element
	this.thumb;
	this.overlay;

	this.scrollY;

	var self = this;

	this.init = function(){
		this.main = document.getElementById('home');
		this.thumb = this.element.querySelector('.semester-thumb');
		this.overlay = this.element.querySelector('.semester-overlay');
		this.close = this.overlay.querySelectorAll('.content-close');

		this.thumb.addEventListener('click', this.onClickThumb);
		
		for( var c = 0; c < this.close.length; c++ )
			this.close[c].addEventListener('click', self.onClickClose);
		
	}

	this.showOverlay = function(){

		this.overlay.classList.remove('out');
		this.overlay.classList.remove('hidden');

		if( this.overlay.scrollTo ) this.overlay.scrollTo(0,0);

		this.overlay.addEventListener( utils.transitionEvent, this.onOverlayIn );

		document.addEventListener('scroll', this.blockScroll);
	}

	this.hideOverlay = function(){
		document.body.classList.remove('overlay');
		this.overlay.classList.add('out');

		if( this.overlay.scrollTo ) window.scrollTo(0,this.scrollY);

		this.overlay.addEventListener( utils.transitionEvent, this.onOverlayOut );

		document.removeEventListener('scroll', this.blockScroll);
	}

	this.onClickThumb = function(e){
		self.showOverlay();
	}

	this.onClickClose = function(e){
		self.hideOverlay();
	}

	this.onOverlayIn = function(){
		self.scrollY = self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop;

		document.body.classList.add('overlay');

		self.overlay.removeEventListener( utils.transitionEvent, self.onOverlayIn );
	}

	this.onOverlayOut = function(){
		self.overlay.classList.add('hidden');

		self.overlay.removeEventListener( utils.transitionEvent, self.onOverlayOut );
	}

	this.blockScroll = function(e){
		e.preventDefault();
	}

	this.init();

}

var init = function(){
	
	var semester = document.querySelectorAll('.semester');
	
	for( var s = 0; s < semester.length; s++ )
		new Semester(semester[s]);

	svg4everybody();

}

module.exports = init();