var utils = require('./../modules/utils.js');

var contentItems, submenuItems, multilayerItems;
var activeClass = "active";

var onSubmenuLink = function(e){

	// unset all links
	for( var s = 0; s < submenuItems.length; s++ )
		submenuItems[s].classList.remove('active');

	e.currentTarget.classList.add(activeClass);

	// unset all content items
	for( var c = 0; c < contentItems.length; c++ )
		contentItems[c].classList.remove('visible');

	// unset all multilayer links
	for( var m = 0; m < multilayerItems.length; m++ ){
		var mi = multilayerItems[m];
		mi.classList.remove('active');
		mi.hash = mi.getAttribute('data-href');
	}

	jump(0);
};

var prepareContentItem = function(item){
	var children = item.children;
	var remove = [];
	var modifier = "";
	
	for( var c = 0; c < children.length; c++ ){
		var child = children[c];
		if( child.children.length == 0 ){
			remove.push(child);
		}else{
			child.classList.remove('col-3');
			child.classList.add('col-9');

			modifier = c == 1 ? "center" : "right";
		}
	}

	for( var r = 0 ; r < remove.length; r++)
		item.removeChild(remove[r]);

	item.classList.add("multilayer");
	item.classList.add(modifier);

};

var onMultilayerLink = function(e){
	e.preventDefault();

	var link = e.currentTarget;
	var parent = utils.findAncestor(link,'item');
	var parentId = parent.id;
	var parentHash = "#" + parentId;

	window.location.hash = link.hash;

	// reset other multilayer links
	for( var m = 0; m < multilayerItems.length; m++ ){
		var mi = multilayerItems[m];
		if( mi != link ) mi.hash = mi.getAttribute('data-href');
	}

	// modify href
	if( link.hash == parentHash) {
		link.hash = link.getAttribute('data-href');
		parent.classList.remove('visible');
	}else{
		link.hash = parentHash;
		parent.classList.add('visible');
	}

	link.classList.toggle(activeClass);

	jump(0);
};

var init = function(){

	var currentItem;
	var visibleSubmenuItems = [];
	var currentHash = window.location.hash;
	
	contentItems = document.querySelectorAll('.item');
	submenuItems = document.querySelectorAll('.submenu-link');
	multilayerItems = document.querySelectorAll('.multilayer-link');

	// init multilayer
	for( var m = 0; m < multilayerItems.length; m++ ){

		var item = multilayerItems[m];
		var hash = item.hash;

		var submenuLink = document.querySelector('#submenu a[href="'+hash+'"]').parentNode;
		var contentItem = document.querySelector('.item'+hash);

		submenuLink.style.display = "none";

		prepareContentItem( contentItem );

		item.setAttribute('data-href',hash);
		item.addEventListener('click',onMultilayerLink);

		if( hash == currentHash ) currentItem = item;

	}

	// select remaining submenu item
	for( var s = 0 ; s < submenuItems.length; s++ ){
		var submenuItem = submenuItems[s];
		if( submenuItem.parentNode.style.display == "" ) 
			visibleSubmenuItems.push(submenuItem)
	}
	if( visibleSubmenuItems.length == 1 ) visibleSubmenuItems[0].click();

	// activate current item
	if( currentItem ){
		currentItem.click();
		// change current hash to set active class in submenu on load
		currentHash = "#" + utils.findAncestor(currentItem,'item').id;
	}

	// init submenu
	for( var s = 0; s < submenuItems.length; s++ ){

		var item = submenuItems[s];

		// add active class if current
		if( item.hash == currentHash ) item.classList.add(activeClass);

		item.addEventListener('click',onSubmenuLink);

	}

};

module.exports = init();