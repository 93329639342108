require('lazysizes');
require('./../../../../node_modules/lazysizes/plugins/respimg/ls.respimg');
require('./../../../../node_modules/lazysizes/plugins/bgset/ls.bgset');

window.jump = require('jump.js');

require('./modules/header.js');

var main = document.querySelector('main');
var pagetype = main.id;

if( pagetype == "basic-page" ){
	require('./pages/basic-page.js');
	document.body.style.height = "100%";
}else if( pagetype == "home" ){
	require('./pages/home.js');
	document.body.style.height = "auto";
}